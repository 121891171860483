import type { DeOrderBookApp } from '@base/types/app'

export interface NavLink {
  path: string
  name: string
  active: boolean
  activeClass: string
  locale: string
  target?: string
  isExternal?: boolean
  isSoon?: boolean
}

export enum DOBApplets {
  APP = 'app',
  NFT = 'nft',
  BAZAAR = 'bazaar',
  EXPLORER = 'explorer',
  PRO = 'pro',
}

const APP_NAV_LINKS: NavLink[] = [
  {
    path: '/',
    name: 'DeOrder',
    active: false,
    activeClass: 'active',
    locale: 'header.deorder',
  },
  {
    path: '/account',
    name: 'Account',
    active: false,
    activeClass: 'active',
    locale: 'header.account',
  },
  {
    path: '/lock',
    name: 'Lock',
    active: false,
    activeClass: 'active',
    locale: 'header.lock',
  },
  {
    path: '/claim',
    name: 'Claim & Exercise',
    active: false,
    activeClass: 'active',
    locale: 'header.exercise',
  },
  {
    path: '/leaderboard',
    name: 'Leaderboard',
    active: false,
    activeClass: 'active',
    locale: 'header.leaderboard',
  },
]

const APP_EXTERNAL_NAV_LINKS: NavLink[] = [
  {
    path: 'https://nft.deorderbook.com',
    name: 'NFT',
    active: false,
    activeClass: 'no-active',
    locale: 'header.nft',
    target: '_blank',
    isExternal: true,
  },
  {
    path: 'https://explorer.deorderbook.com',
    name: 'Explorer',
    active: false,
    activeClass: 'no-active',
    locale: 'header.explorer',
    target: '_blank',
    isExternal: true,
  },
  {
    path: 'https://docs.deorderbook.com',
    name: 'Docs',
    active: false,
    activeClass: 'no-active',
    locale: 'header.docs',
    target: '_blank',
    isExternal: true,
  },
  {
    path: 'https://bazaar.deorderbook.com',
    name: 'Bazaar',
    active: false,
    activeClass: 'no-active',
    locale: 'header.bazaar',
    target: '_blank',
    isExternal: true,
    isSoon: true,
  },
]

const BAZAAR_NAV_LINKS: NavLink[] = [
  {
    path: '/bullet/bullet-market',
    name: 'bulletMarket',
    active: false,
    activeClass: 'active',
    locale: 'header.bulletMarket',
  },
  {
    path: '/bullet/my-bullet',
    name: 'myBullet',
    active: false,
    activeClass: 'active',
    locale: 'header.myBullet',
  },
]

const NFT_NAV_LINKS: NavLink[] = []
const EXPLORER_NAV_LINKS: NavLink[] = []
const PRO_NAV_LINKS: NavLink[] = [
  {
    path: '/',
    name: 'Options',
    active: false,
    activeClass: 'active',
    locale: 'header.options',
  },
  {
    path: '/approve',
    name: 'Approve',
    active: false,
    activeClass: 'active',
    locale: 'header.approve',
  },
  {
    path: '/settings',
    name: 'Settings',
    active: false,
    activeClass: 'active',
    locale: 'header.settings',
  },
]

export const NAV_LINKS: {
  [key in DOBApplets]: { main: NavLink[]; external?: NavLink[] }
} = {
  app: {
    main: APP_NAV_LINKS,
    external: APP_EXTERNAL_NAV_LINKS,
  },
  bazaar: { main: BAZAAR_NAV_LINKS },
  nft: { main: NFT_NAV_LINKS },
  explorer: { main: EXPLORER_NAV_LINKS },
  pro: { main: PRO_NAV_LINKS },
}

export const WALLETS = reactive([
  { label: 'MetaMask', id: 'metamask' },
  { label: 'Wallet Connect', id: 'walletconnect' },
  { label: 'Binance Chain Wallet', id: 'binanceWallet' },
])

export const UPDATE_DURATION = 15 * 1000 // 15 seconds
export const BLOCK_PER_MINUTE = 5
export const BLOCK_PER_DAY = 24 * 60 * BLOCK_PER_MINUTE
export const BLOCK_PER_YEAR = 365 * BLOCK_PER_DAY

export const ERROR_CODE = {
  REQUEST_PENDING: -32002,
  ACTION_REJECTED: 'ACTION_REJECTED',
  USER_REJECTED: 4001,
} as const

export const DOB_EXTERNAL_LINKS_MAP = {
  APP: {
    name: 'DeOrderBook',
    url: 'https://app.deorderbook.com',
  },
  BAZAAR: {
    name: 'Bazaar',
    url: 'https://bazaar.deorderbook.com',
  },
  EXPLORER: {
    name: 'Explorer',
    url: 'https://explorer.deorderbook.com',
  },
  STATS: {
    name: 'Stats',
    url: 'https://stats.deorderbook.com',
  },
  DOCS: {
    name: 'Docs',
    url: 'https:/docs.deorderbook.com',
  },
  HOME: {
    name: 'DeOrderBook',
    url: 'https://deorderbook.com',
  },
}

export const DOB_APPS_MAP: {
  [key in DeOrderBookApp]: {
    name: string
    showGasInfo: boolean
    showConnectWallet: boolean
    showMenu: boolean
    showNetworkSelect: boolean
    showLaunchApp?: boolean
    showFaucetInfo?: boolean
    links: { name: string; url: string }[]
    externalLinks: { name: string; url: string }[]
  }
} = {
  main: {
    name: '',
    links: [
      {
        name: 'header.deorder',
        url: '/',
      },
      {
        name: 'header.pools',
        url: '/pools',
      },
      {
        name: 'header.lock-dob',
        url: '/lock',
      },
      {
        name: 'header.dashboard',
        url: '/dashboard',
      },
    ],
    showGasInfo: true,
    showConnectWallet: true,
    showMenu: true,
    showNetworkSelect: true,
    showFaucetInfo: true,
    externalLinks: [
      DOB_EXTERNAL_LINKS_MAP.BAZAAR,
      DOB_EXTERNAL_LINKS_MAP.EXPLORER,
      DOB_EXTERNAL_LINKS_MAP.STATS,
      DOB_EXTERNAL_LINKS_MAP.DOCS,
    ],
  },
  home: {
    name: '',
    showGasInfo: false,
    showConnectWallet: false,
    showMenu: false,
    showNetworkSelect: false,
    showLaunchApp: true,
    links: [],
    externalLinks: [
      DOB_EXTERNAL_LINKS_MAP.APP,
      DOB_EXTERNAL_LINKS_MAP.BAZAAR,
      DOB_EXTERNAL_LINKS_MAP.EXPLORER,
      DOB_EXTERNAL_LINKS_MAP.STATS,
    ],
  },
  pro: {
    name: 'Pro',
    links: [
      {
        name: 'Create Options',
        url: '/',
      },
      {
        name: 'Settings',
        url: '/settings',
      },
    ],
    showGasInfo: true,
    showConnectWallet: true,
    showMenu: true,
    showNetworkSelect: true,
    externalLinks: [
      DOB_EXTERNAL_LINKS_MAP.APP,
      DOB_EXTERNAL_LINKS_MAP.BAZAAR,
      DOB_EXTERNAL_LINKS_MAP.EXPLORER,
      DOB_EXTERNAL_LINKS_MAP.STATS,
      DOB_EXTERNAL_LINKS_MAP.DOCS,
    ],
  },
  bazaar: {
    name: 'Bazaar',
    links: [
      {
        name: 'My BULLET',
        url: '/',
      },
      {
        name: 'Buy BULLET',
        url: '/buy',
      },
    ],
    showGasInfo: true,
    showConnectWallet: true,
    showMenu: true,
    showNetworkSelect: true,
    externalLinks: [
      DOB_EXTERNAL_LINKS_MAP.APP,
      DOB_EXTERNAL_LINKS_MAP.EXPLORER,
      DOB_EXTERNAL_LINKS_MAP.STATS,
      DOB_EXTERNAL_LINKS_MAP.DOCS,
    ],
  },
  explorer: {
    name: 'Explorer',
    showGasInfo: false,
    showConnectWallet: false,
    showMenu: true,
    showNetworkSelect: true,
    links: [],
    externalLinks: [
      DOB_EXTERNAL_LINKS_MAP.APP,
      DOB_EXTERNAL_LINKS_MAP.BAZAAR,
      DOB_EXTERNAL_LINKS_MAP.STATS,
      DOB_EXTERNAL_LINKS_MAP.DOCS,
    ],
  },
  nft: {
    name: 'NFT',
    showGasInfo: true,
    showConnectWallet: true,
    showMenu: true,
    showNetworkSelect: true,
    links: [],
    externalLinks: [
      DOB_EXTERNAL_LINKS_MAP.APP,
      DOB_EXTERNAL_LINKS_MAP.BAZAAR,
      DOB_EXTERNAL_LINKS_MAP.EXPLORER,
      DOB_EXTERNAL_LINKS_MAP.STATS,
      DOB_EXTERNAL_LINKS_MAP.DOCS,
    ],
  },
  stats: {
    name: 'Stats',
    showGasInfo: false,
    showConnectWallet: false,
    showMenu: true,
    showNetworkSelect: true,
    links: [],
    externalLinks: [
      DOB_EXTERNAL_LINKS_MAP.APP,
      DOB_EXTERNAL_LINKS_MAP.BAZAAR,
      DOB_EXTERNAL_LINKS_MAP.EXPLORER,
      DOB_EXTERNAL_LINKS_MAP.DOCS,
    ],
  },
}

export const META_IMAGES = {
  LARGE: `${DOB_EXTERNAL_LINKS_MAP.HOME.url}/deorderbook-banner-large.webp`,
  SMALL: `${DOB_EXTERNAL_LINKS_MAP.HOME.url}/deorderbook-banner-small.webp`,
}
